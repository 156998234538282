export enum KpiTypeDTO {
    ALERT_LEVEL = 'Alert Level',
    LOSS_FLOW = 'Loss Flow',
    FLOW_PER_HOUSE_CONNECTION = 'Flow Per House Connection',
    FLOW_PER_PIPE_LENGTH = 'Flow Per Pipe Length'
}

export class DetectKpisDTO {
    constructor(
        readonly providerType: string,
        readonly timestamp: string | undefined,
        readonly eventType: string | undefined,
        readonly alertLevel: number,
        readonly lossFlow: number,
        readonly flowPerHouseConnection: number,
        readonly flowPerPipeLength: number,
        readonly lossFlowMinimumNightFlow: number,
        readonly flowPerHouseConnectionMinimumNightFlow: number,
        readonly flowPerPipeLengthMinimumNightFlow: number,
        readonly lossVolume: number,
        readonly mnf: number
    ) {}
}

export class QualityKpisDTO {
    constructor(
        readonly providerType: string,
        readonly timestamp: string | undefined,
        readonly positiveNightFlow: boolean | null,
        readonly missingData: number
    ) {}
}

export class KpisDTO {
    constructor(readonly detect: DetectKpisDTO | null, readonly quality: QualityKpisDTO | null) {}
}
