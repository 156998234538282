type ParamValue = string | number | boolean | string[] | number[] | boolean[]

type Params<T extends Record<string, ParamValue>> = {
    [K in keyof T]: T[K] extends string[] | number[] | boolean[] ? T[K][number] : T[K]
}

export function parseParams<T extends Record<string, ParamValue>>(params: Params<T>): string {
    const keys = Object.keys(params)
    let options = ''

    keys.forEach((key) => {
        const paramValue = params[key]
        if (!paramValue) {
            return
        }

        const isParamTypeObject = typeof paramValue === 'object'

        if (!isParamTypeObject) {
            options += `${key}=${paramValue}&`
        }

        if (Array.isArray(paramValue)) {
            (paramValue as ParamValue[]).forEach((element) => {
                options += `${key}=${element}&`
            })
        }
    })

    return options ? options.slice(0, -1) : options
}
