import { PreferencesDTOFactory } from './PreferencesDTOFactory'
import { UserDTO, UserUpdateDTO } from './UserDTO'
import { UserRaw, UserUpdateRaw } from './UserRaw'
import { ScopeDTOFactory } from '@/api/user/dto/ScopeDTOFactory'

export abstract class UserDTOFactory {
    static fromRaw(userRaw: UserRaw): UserDTO {
        return new UserDTO(
            userRaw.id,
            userRaw.first_name,
            userRaw.last_name,
            userRaw.email,
            PreferencesDTOFactory.fromRaw(userRaw.preferences),
            userRaw.scopes ? userRaw.scopes.map(ScopeDTOFactory.fromRaw) : []
        )
    }

    static fromUpdateDTO(dto: UserUpdateDTO): Partial<UserUpdateRaw> {
        return {
            username: dto.username,
            first_name: dto.firstName,
            last_name: dto.lastName,
            email: dto.email,
            preferences: dto.preferences ? PreferencesDTOFactory.fromUpdateDTO(dto.preferences) : undefined,
            password: dto.newPassword,
            confirm_password: dto.confirmPassword,
            old_password: dto.currentPassword,
            scopes: dto.scopes ? dto.scopes.map(ScopeDTOFactory.fromUpdateDTO) : undefined
        }
    }
}
