import { IOrganizationAPI, OrganizationAPI } from '@/api/organization'
import { UserAPI, UserUpdateDTO } from '@/api/user'

import { Organization, OrganizationUpdate, User, UserUpdate } from '@/domains/common/models'
import { IUserService, OrganizationFactory, UserFactory } from '@/domains/common/services/user'
import { BaseService } from '@/domains/common/services/BaseService'

export class UserService extends BaseService implements IUserService {
    private readonly userApi: UserAPI = new UserAPI()
    private readonly organizationApi: IOrganizationAPI = new OrganizationAPI()

    private _user: User | null = null
    private _organizationSlug: string | null = null
    private _organization: Organization | null = null

    public async getIdentity(): Promise<User> {
        if (this._user) {
            return this._user
        }

        const dto = await this.userApi.getIdentity()
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }

        const user = UserFactory.fromDTO(dto)
        this._user = user

        const organization = user.firstOrganization
        if (organization) {
            this._organizationSlug = organization.slug
            await this.getOrganization()
        }

        return user
    }

    public async getOrganization(): Promise<Organization | null> {
        if (this._organization) {
            return this._organization
        }

        return await this.retrieveOrganization()
    }

    public async retrieveOrganization(): Promise<Organization | null> {
        if (!this._organizationSlug) {
            throw new Error()
        }

        const dto = await this.organizationApi.getBySlug(this._organizationSlug)
        if (!dto) {
            throw new Error()
        }

        const organization = OrganizationFactory.fromDTO(dto)
        this._organization = organization

        return organization
    }

    public async updateUser(userId: string, userUpdate: UserUpdate): Promise<User> {
        const dto = await this.userApi.updateUser(userId, UserFactory.fromUpdateModel(userUpdate))
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }

        return UserFactory.fromDTO(dto)
    }

    public async updateUserPassword(
        userId: string,
        currentPassword: string,
        newPassword: string,
        confirmPassword: string
    ): Promise<User> {
        const dto = await this.userApi.updateUser(
            userId,
            new UserUpdateDTO({
                newPassword: newPassword,
                confirmPassword: confirmPassword,
                currentPassword: currentPassword
            })
        )
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }

        return UserFactory.fromDTO(dto)
    }

    public async updateOrganization(organizationId: string, changes: OrganizationUpdate): Promise<Organization> {
        const dto = await this.organizationApi.update(organizationId, OrganizationFactory.fromUpdateModel(changes))
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }

        return OrganizationFactory.fromDTO(dto)
    }

    public clear(): void {
        this._user = null
        this._organization = null
    }
}
