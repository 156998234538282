import { Point } from 'geojson'

export class SensorUpdateDTO {
    constructor(
        readonly externalId: string | undefined,
        readonly name: string | undefined,
        readonly timezone: string | undefined,
        readonly position: Point | undefined
    ) {}
}
