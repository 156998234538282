import { invert } from 'lodash'
import { KpiTypeDTO } from '@/api/data-management/network'
import { SettingsDTO } from '@/api/organization'
import { Settings } from '@/domains/common/models/Settings'
import { KpiType } from '@/domains/common/models/enums/KpiType'

export abstract class SettingsFactory {
    static fromDTO(dto: SettingsDTO): Settings {
        return new Settings(
            dto.detectOverviewMapDefaultKpi ? SettingsFactory.fromKpiTypeDTO(dto.detectOverviewMapDefaultKpi) : null
        )
    }

    static fromModel(model: Partial<Settings>): SettingsDTO {
        return new SettingsDTO(
            model.detectOverviewMapDefaultKpi
                ? SettingsFactory.fromKpiTypeModel(model.detectOverviewMapDefaultKpi)
                : null
        )
    }

    private static kpiTypeDTOToModelMappings: Record<KpiTypeDTO, KpiType> = {
        'Alert Level': KpiType.ALERT_LEVEL,
        'Loss Flow': KpiType.LOSS_FLOW,
        'Flow Per House Connection': KpiType.FLOW_PER_HOUSE_CONNECTION,
        'Flow Per Pipe Length': KpiType.FLOW_PER_PIPE_LENGTH
    }

    static fromKpiTypeDTO(dto: KpiTypeDTO): KpiType {
        return SettingsFactory.kpiTypeDTOToModelMappings[dto] || KpiType.ALERT_LEVEL
    }

    static fromKpiTypeModel(model: KpiType): KpiTypeDTO {
        return (invert(SettingsFactory.kpiTypeDTOToModelMappings)[model] as KpiTypeDTO) || KpiTypeDTO.ALERT_LEVEL
    }
}
