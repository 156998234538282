import { EngineEventStatusDTO } from './EngineEventBaseDTO'

export class EngineEventDataPointDTO {
    constructor(
        readonly timestamp: string,
        readonly values: { [key: string]: unknown },
        readonly status: EngineEventStatusDTO,
        readonly level: number,
        readonly start?: string,
        readonly end?: string
    ) {}
}
