import { invert } from 'lodash'
import {
    ConnectionDirectionDTO,
    ConnectionDTO,
    ConnectionRaw,
    ConnectionUpdateDTO,
    ConnectionUpdateRaw,
    DirectionRaw,
    DmaCreateDTO,
    DmaCreateRaw,
    DmaDetailDTO,
    DmaDetailRaw,
    DmaDTO,
    DmaRaw,
    DmaTransferDTO,
    DmaTransferRaw,
    DmaUpdateDTO,
    DmaUpdateRaw,
    EnginesDTO
} from '../dto'
import { EnginesDTOFactory } from './EnginesDTOFactory'
import { SensorDTOFactory } from './SensorDTOFactory'

export abstract class DmaDTOFactory {
    private static directionRawToDTOMappings: Record<DirectionRaw, ConnectionDirectionDTO> = {
        input: ConnectionDirectionDTO.INPUT,
        output: ConnectionDirectionDTO.OUTPUT
    }

    static fromRaw(dma: DmaRaw): DmaDTO {
        return new DmaDTO(
            dma.id,
            dma.zone_id,
            dma.external_id,
            dma.name,
            dma.description,
            dma.timezone,
            dma.active,
            dma.pipe_length,
            dma.time_step,
            dma.average_window,
            dma.grace_period,
            dma.inhabitants_count,
            dma.average_pressure,
            dma.nightflow_start_hour,
            dma.nightflow_end_hour,
            dma.minimum_night_flow,
            dma.invoiced_water_volume_per_year,
            dma.geometry ? JSON.parse(dma.geometry) : null
        )
    }

    static fromDetailRaw(dma: DmaDetailRaw): DmaDetailDTO {
        return new DmaDetailDTO(
            dma.id,
            dma.zone_id,
            dma.external_id,
            dma.name,
            dma.description,
            dma.timezone,
            dma.active,
            dma.pipe_length,
            dma.time_step,
            dma.average_window,
            dma.grace_period,
            dma.inhabitants_count,
            dma.house_connections,
            dma.average_pressure,
            dma.nightflow_start_hour,
            dma.nightflow_end_hour,
            dma.minimum_night_flow,
            dma.invoiced_water_volume_per_year,
            dma.geometry,
            dma.connections ? dma.connections.map(DmaDTOFactory.fromRawConnection) : [],
            dma.engines ? EnginesDTOFactory.fromRaw(dma.engines) : new EnginesDTO([], [], [], null, null, null)
        )
    }

    static fromCreateDTO(dto: DmaCreateDTO): DmaCreateRaw {
        return {
            zone_id: dto.zoneId,
            external_id: dto.externalId,
            name: dto.name,
            description: dto.description,
            timezone: dto.timezone,
            active: dto.active,
            pipe_length: dto.pipeLength,
            time_step: dto.timeStep,
            average_window: dto.averageWindow,
            grace_period: dto.gracePeriod,
            inhabitants_count: dto.inhabitantsCount,
            house_connections: dto.houseConnections,
            average_pressure: dto.averagePressure,
            nightflow_start_hour: this.formatDatetime(dto.nightflowStartHour),
            nightflow_end_hour: this.formatDatetime(dto.nightflowEndHour),
            minimum_night_flow: dto.minimumNightFlow,
            invoiced_water_volume_per_year: dto.invoicedWaterVolumePerYear,
            geometry: dto.geometry
        }
    }

    static fromUpdateDTO(dto: DmaUpdateDTO): DmaUpdateRaw {
        return {
            zone_id: dto.zoneId,
            external_id: dto.externalId,
            name: dto.name,
            description: dto.description,
            timezone: dto.timezone,
            active: dto.active,
            pipe_length: dto.pipeLength,
            time_step: dto.timeStep,
            average_window: dto.averageWindow,
            grace_period: dto.gracePeriod,
            inhabitants_count: dto.inhabitantsCount,
            house_connections: dto.houseConnections,
            average_pressure: dto.averagePressure,
            nightflow_start_hour:
                dto.nightflowStartHour !== undefined ? this.formatDatetime(dto.nightflowStartHour) : undefined,
            nightflow_end_hour:
                dto.nightflowEndHour !== undefined ? this.formatDatetime(dto.nightflowEndHour) : undefined,
            minimum_night_flow: dto.minimumNightFlow,
            invoiced_water_volume_per_year: dto.invoicedWaterVolumePerYear,
            geometry: dto.geometry
        }
    }

    static fromRawConnection(connection: ConnectionRaw): ConnectionDTO {
        return new ConnectionDTO(
            SensorDTOFactory.fromRaw(connection.sensor),
            DmaDTOFactory.directionRawToDTOMappings[connection.direction] || ConnectionDirectionDTO.INPUT
        )
    }

    static fromConnectionUpdateDto(connection: ConnectionUpdateDTO): ConnectionUpdateRaw {
        return {
            sensor_id: connection.sensorId,
            direction:
                (invert(DmaDTOFactory.directionRawToDTOMappings)[connection.direction] as DirectionRaw) || 'input'
        }
    }

    static fromTransferDTO(dto: DmaTransferDTO): DmaTransferRaw {
        return {
            dma_id: dto.dmaId,
            zone_id: dto.zoneId
        }
    }

    private static formatDatetime(datetime: Date): string {
        const hours = String(datetime.getHours()).padStart(2, '0')
        const minutes = String(datetime.getMinutes()).padStart(2, '0')
        const seconds = String(datetime.getSeconds()).padStart(2, '0')
        return `${hours}:${minutes}:${seconds}`
    }
}
