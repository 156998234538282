import { Geometry } from 'geojson'
import { ConnectionDTO } from './ConnectionDTO'
import { EnginesDTO } from './EnginesDTO'

export class DmaDetailDTO {
    constructor(
        readonly id: string,
        readonly zoneId: string,
        readonly externalId: string,
        readonly name: string,
        readonly description: string,
        readonly timezone: string,
        readonly active: boolean,
        readonly pipeLength: number,
        readonly timeStep: number,
        readonly averageWindow: number,
        readonly gracePeriod: number,
        readonly inhabitantsCount: number | null,
        readonly houseConnections: number | null,
        readonly averagePressure: number | null,
        readonly nightflowStartHour: string,
        readonly nightflowEndHour: string,
        readonly minimumNightFlow: number,
        readonly invoicedWaterVolumePerYear: number,
        readonly geometry: Geometry,
        readonly connections: ConnectionDTO[],
        readonly engines: EnginesDTO
    ) {}
}
