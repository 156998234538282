import { TaskStatusDTO, TaskStatusRaw } from "../dto"

export abstract class TaskStatusDTOFactory {
  static fromRaw(statusRaw: TaskStatusRaw): TaskStatusDTO {
      switch (statusRaw) {
          case TaskStatusRaw.ONGOING:
              return TaskStatusDTO.ONGOING
          case TaskStatusRaw.CANCELED:
              return TaskStatusDTO.CANCELED
          case TaskStatusRaw.PENDING:
              return TaskStatusDTO.PENDING
          default:
              return TaskStatusDTO.FINISHED
      }
  }
}