import {
    ComputeDmaBalanceTaskCreateDTO,
    ComputeDmaBalanceTaskDTO,
    ComputeDmaBalanceTaskRaw,
    ComputeEngineConfigurationTaskCreateDTO,
    ComputeEngineConfigurationTaskDTO,
    ComputeEngineConfigurationTaskRaw,
    isComputeEngineConfigurationTaskRaw
} from '../dto'

export abstract class ComputeTaskDTOFactory {
    static fromRaw(
        task: ComputeDmaBalanceTaskRaw | ComputeEngineConfigurationTaskRaw
    ): ComputeDmaBalanceTaskDTO | ComputeEngineConfigurationTaskDTO {
        if (isComputeEngineConfigurationTaskRaw(task)) {
            return new ComputeEngineConfigurationTaskDTO(
                task.type,
                task.created_on,
                task.reference_start,
                task.depends_on,
                task.dma_id,
                task.provider_type,
                task.configuration_id
            )
        } else {
            return new ComputeDmaBalanceTaskDTO(
                task.type,
                task.created_on,
                task.reference_start,
                task.depends_on,
                task.dma_id
            )
        }
    }

    static fromCreateDTO(
        dto: ComputeDmaBalanceTaskCreateDTO | ComputeEngineConfigurationTaskCreateDTO
    ): ComputeDmaBalanceTaskRaw | ComputeEngineConfigurationTaskRaw | null {
        if (dto instanceof ComputeDmaBalanceTaskCreateDTO) {
            return {
                type: dto.type,
                created_on: this.formatDatetime(dto.createdOn),
                reference_start: this.formatDatetime(dto.referenceStart),
                depends_on: dto.dependsOn,
                dma_id: dto.dmaId
            }
        } else if (dto instanceof ComputeEngineConfigurationTaskCreateDTO) {
            return {
                type: dto.type,
                created_on: this.formatDatetime(dto.createdOn),
                reference_start: this.formatDatetime(dto.referenceStart),
                depends_on: dto.dependsOn,
                dma_id: dto.dmaId,
                provider_type: dto.providerType,
                configuration_id: dto.configurationId
            }
        }

        return null
    }

    private static formatDatetime(datetime: Date): string {
        return datetime.toISOString()
    }
}
