import { DmaPreviewDTO, TaskPreviewDTO, DmaTaskOverviewItemDTO } from '../dto'
import { DmaPreviewRaw, TaskPreviewRaw, DmaTaskOverviewItemRaw } from '../dto/DmaTaskOverviewItemRaw'
import { TaskStatusDTOFactory } from './TaskStatusDTOFactory'
import { UserDTOFactory } from './UserDTOFactory'

abstract class DmaPreviewFactory {
    static fromRaw(dmaRaw: DmaPreviewRaw): DmaPreviewDTO {
        return new DmaPreviewDTO(dmaRaw.id, dmaRaw.name)
    }
}

abstract class TaskPreviewFactory {
    static fromRaw(taskRaw: TaskPreviewRaw | null): TaskPreviewDTO | null {
        if (taskRaw == null) {
            return null
        }
        return new TaskPreviewDTO(
            taskRaw.id,
            TaskStatusDTOFactory.fromRaw(taskRaw.status),
            taskRaw.created_on,
            taskRaw.last_update,
            taskRaw.created_by ? UserDTOFactory.fromRaw(taskRaw.created_by) : null,
            taskRaw.report,
            taskRaw.provider_type
        )
    }
}

export abstract class DmaTasksOverviewItemDTOFactory {
    static fromRaw(raw: DmaTaskOverviewItemRaw): DmaTaskOverviewItemDTO {
        return new DmaTaskOverviewItemDTO(DmaPreviewFactory.fromRaw(raw.dma), TaskPreviewFactory.fromRaw(raw.task))
    }
}
