import { OrganizationDTO } from '@/api/organization'

export enum ScopeRoleDTO {
    MASTER_ADMINISTRATOR = 'master-administrator',
    ORGANIZATION_ADMINISTRATOR = 'organization-administrator',
    MANAGER = 'manager',
    OPERATOR = 'operator'
}

export class ScopeDTO {
    constructor(readonly organization: OrganizationDTO, readonly role: ScopeRoleDTO) {}
}

export class ScopeUpdateDTO {
    constructor(readonly organization_id: string, readonly role: ScopeRoleDTO) {}
}
