import { invert } from 'lodash'
import {
    SensorCreateDTO,
    SensorCreateRaw,
    SensorDetailDTO,
    SensorDetailRaw,
    SensorTypeDTO,
    SensorTypeRaw,
    SensorUpdateDTO,
    SensorUpdateRaw
} from '../dto'

export abstract class SensorDTOFactory {
    private static dataSourceTypeToModelMappings: Record<SensorTypeDTO, SensorTypeRaw> = {
        [SensorTypeDTO.FLOW]: 'Flow',
        [SensorTypeDTO.PRESSURE]: 'Pressure',
        [SensorTypeDTO.FLOW_PRESSURE]: 'FlowPressure'
    }

    static fromRaw(sensor: SensorDetailRaw): SensorDetailDTO {
        return new SensorDetailDTO(
            sensor.id,
            sensor.name,
            SensorDTOFactory.dataSourceTypeRawToDTO(sensor.type),
            sensor.external_id,
            sensor.timezone,
            sensor.position
        )
    }

    static fromCreateDTO(dto: SensorCreateDTO): SensorCreateRaw {
        return {
            external_id: dto.externalId,
            name: dto.name,
            type: SensorDTOFactory.dataSourceTypeToModelMappings[dto.type],
            timezone: dto.timezone,
            position: dto.position,
        }
    }

    static dataSourceTypeRawToDTO(raw: SensorTypeRaw): SensorTypeDTO {
        return invert(this.dataSourceTypeToModelMappings)[raw] as SensorTypeDTO
    }

    static fromUpdateDTO(dto: SensorUpdateDTO): SensorUpdateRaw {
        return {
            external_id: dto.externalId,
            name: dto.name,
            timezone: dto.timezone,
            position: dto.position
        }
    }
}
