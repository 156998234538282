import { ApplicationDTO, ApplicationRaw } from '@/api/application'

export abstract class ApplicationDTOFactory {
    public static fromRaw(raw: ApplicationRaw): ApplicationDTO {
        return new ApplicationDTO(raw.id, raw.provider_type, raw.instance_name, raw.organization_id, raw.type)
    }

    public static fromDTO(dto: ApplicationDTO): ApplicationRaw {
        return {
            id: dto.id,
            provider_type: dto.providerType,
            instance_name: dto.instanceName,
            organization_id: dto.organizationId,
            type: dto.type
        }
    }
}
