import { PreferencesDTO } from './PreferencesDTO'
import { ScopeDTO, ScopeUpdateDTO } from './ScopeDTO'

export class UserDTO {
    constructor(
        readonly id: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string,
        readonly preferences: PreferencesDTO,
        readonly scopes: ScopeDTO[]
    ) {}
}

export class UserUpdateDTO {
    readonly username?: string
    readonly firstName?: string
    readonly lastName?: string
    readonly email?: string
    readonly preferences?: Partial<PreferencesDTO>
    readonly newPassword?: string
    readonly confirmPassword?: string
    readonly currentPassword?: string
    readonly scopes?: ScopeUpdateDTO[]

    constructor(base: Partial<UserUpdateDTO>) {
        this.username = base.username
        this.firstName = base.firstName
        this.lastName = base.lastName
        this.email = base.email
        this.preferences = base.preferences
        this.newPassword = base.newPassword
        this.confirmPassword = base.confirmPassword
        this.currentPassword = base.currentPassword
        this.scopes = base.scopes
    }
}
