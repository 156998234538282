import { ComputeDmaBalanceTaskRaw } from './ComputeDmaBalanceTaskRaw'
import { ComputeTaskTypeEnum } from './ComputeTaskTypeEnum'

export interface ComputeEngineConfigurationTaskRaw {
    type: ComputeTaskTypeEnum
    created_on: string
    reference_start: string
    depends_on: string[]
    dma_id: string
    provider_type: string
    configuration_id: string
}

export function isComputeEngineConfigurationTaskRaw(
    obj: ComputeDmaBalanceTaskRaw | ComputeEngineConfigurationTaskRaw
): obj is ComputeEngineConfigurationTaskRaw {
    return 'provider_type' in obj && 'configuration_id' in obj
}
