import { ApplicationDTO } from '@/api/application'
import { Application } from '@/domains/common/models'

export abstract class ApplicationFactory {
    static fromDTO(dto: ApplicationDTO): Application {
        return new Application(dto.id, dto.providerType, dto.instanceName, dto.organizationId, dto.type)
    }

    static fromModel(model: Application): ApplicationDTO {
        return new ApplicationDTO(model.id, model.providerType, model.instanceName, model.organizationId, model.type)
    }
}
