export class EngineConfigurationDTO {
    constructor(
        readonly id: string,
        readonly providerType: string,
        readonly name: string,
        readonly earliestComputableDate: string | null,
        readonly active: boolean,
        readonly settings: { [key: string]: unknown },
        readonly lastResult: { [key: string]: unknown } | null
    ) {}
}
