import { TaskStatusDTO } from './TaskStatusDTO'
import { UserDTO } from './UserDTO'

export class DmaTaskItemDTO {
    private _id: string
    private _status: TaskStatusDTO
    private _createdOn: string
    private _lastUpdate: string
    private _createdBy: UserDTO | null
    private _report: string | null
    private _providerType: string

    constructor(
        id: string,
        status: TaskStatusDTO,
        createdOn: string,
        lastUpdate: string,
        createdBy: UserDTO | null,
        report: string | null,
        providerType: string
    ) {
        this._id = id
        this._status = status
        this._createdOn = createdOn
        this._lastUpdate = lastUpdate
        this._createdBy = createdBy
        this._report = report
        this._providerType = providerType
    }

    public get id(): string {
        return this._id
    }
    public set id(id: string) {
        this._id = id
    }
    public get report(): string | null {
        return this._report
    }
    public set report(report: string | null) {
        this._report = report
    }
    public get providerType(): string {
        return this._providerType
    }
    public set providerType(providerType: string) {
        this._providerType = providerType
    }
    public get status(): TaskStatusDTO {
        return this._status
    }
    public set status(status: TaskStatusDTO) {
        this._status = status
    }
    public get createdOn(): string {
        return this._createdOn
    }
    public set createdOn(createdOn: string) {
        this._createdOn = createdOn
    }
    public get lastUpdate(): string {
        return this._lastUpdate
    }
    public set lastUpdate(lastUpdate: string) {
        this._lastUpdate = lastUpdate
    }
    public get createdBy(): UserDTO | null {
        return this._createdBy
    }
    public set createdBy(createdBy: UserDTO | null) {
        this._createdBy = createdBy
    }
}
