import { ComputeTaskTypeEnum } from './ComputeTaskTypeEnum'

export class ComputeDmaBalanceTaskDTO {
    constructor(
        readonly type: ComputeTaskTypeEnum,
        readonly createdOn: string,
        readonly referenceStart: string,
        readonly dependsOn: string[],
        readonly dmaId: string
    ) {}
}
