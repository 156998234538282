import { BillingType, LoginType } from '@/domains/usage/models'
import { ScopeRoleDTO } from './ScopeDTO'

export class PartialUserDTO {
    constructor(
        readonly id: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string,
        readonly lastLogin: string | null,
        readonly role: ScopeRoleDTO | null,
        readonly loginType: LoginType,
        readonly billingType: BillingType
    ) {}
}
