import { OrganizationDTO, OrganizationUpdateDTO } from '@/api/organization'
import { Organization, OrganizationUpdate } from '@/domains/common/models'
import { ApplicationFactory } from './ApplicationFactory'
import { SettingsFactory } from './SettingsFactory'

export abstract class OrganizationFactory {
    static fromDTO(dto: OrganizationDTO): Organization {
        return new Organization(
            dto.id,
            dto.name,
            dto.slug,
            dto.applications.map(ApplicationFactory.fromDTO),
            dto.domain,
            SettingsFactory.fromDTO(dto.settings)
        )
    }

    static fromUpdateModel(model: OrganizationUpdate): OrganizationUpdateDTO {
        return new OrganizationUpdateDTO({
            settings: SettingsFactory.fromModel(model.settings)
        })
    }
}
