import { EngineConfigurationDTO } from './EngineConfigurationDTO'

export class EnginesDTO {
    constructor(
        readonly detect: EngineConfigurationDTO[],
        readonly monitor: EngineConfigurationDTO[],
        readonly quality: EngineConfigurationDTO[],
        readonly detectOperationalConfigurationId: string | null,
        readonly monitorOperationalConfigurationId: string | null,
        readonly qualityOperationalConfigurationId: string | null
    ) {}
}
