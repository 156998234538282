import { ComputeTaskTypeEnum } from './ComputeTaskTypeEnum'

export class ComputeEngineConfigurationTaskCreateDTO {
    constructor(
        readonly type: ComputeTaskTypeEnum,
        readonly createdOn: Date,
        readonly referenceStart: Date,
        readonly dependsOn: string[],
        readonly dmaId: string,
        readonly providerType: string,
        readonly configurationId: string
    ) {}
}
