export enum EngineEventTypeDTO {
    BURST = 'Burst',
    MEDIUM = 'Medium Leak',
    CUMULATIVE_LEAK = 'Cumulative Leak',
    LOW = 'Low Leak',
    BAD_DATA_QUALITY = 'Bad Data Quality'
}

export enum EngineEventStatusDTO {
    PENDING = 'Pending',
    CANCELLED = 'Cancelled',
    ACTIVE = 'Active',
    TERMINATING = 'Terminating',
    FINISHED = 'Finished'
}

export abstract class EngineEventBaseDTO {
    protected constructor(
        readonly id: string,
        readonly dmaId: string,
        readonly dmaName: string,
        readonly dmaExternalId: string,
        readonly configurationId: string,
        readonly providerType: string,
        readonly type: EngineEventTypeDTO,
        readonly createdOn: string,
        readonly lastUpdatedOn: string,
        readonly firstTimestamp?: string,
        readonly lastTimestamp?: string
    ) {}
}
