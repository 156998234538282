import { ApplicationDTO } from '@/api/application'
import { SettingsDTO } from '@/api/organization'

export class OrganizationDTO {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly slug: string,
        readonly applications: ApplicationDTO[],
        readonly domain: string,
        readonly settings: SettingsDTO
    ) {}
}

export class OrganizationUpdateDTO {
    readonly settings?: Partial<SettingsDTO>

    constructor(base: Partial<OrganizationUpdateDTO>) {
        this.settings = base.settings
    }
}
