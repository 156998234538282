import axios, { AxiosError, CancelTokenSource } from 'axios'
import { DateTime } from 'luxon'

import { CancelSource, makeURL } from '@/api/config'
import { ServerError } from '@/api/errors/ServerError'

import {
    AggregatorDTO,
    ComputeDmaBalanceTaskCreateDTO,
    ComputeDmaBalanceTaskDTO,
    ComputeDmaBalanceTaskRaw,
    ComputeEngineConfigurationTaskCreateDTO,
    ComputeEngineConfigurationTaskDTO,
    ComputeEngineConfigurationTaskRaw,
    ConnectionDTO,
    ConnectionRaw,
    ConnectionUpdateDTO,
    ConnectionUpdateRaw,
    DmaCreateDTO,
    DmaCreateRaw,
    DmaDetailDTO,
    DmaDetailRaw,
    DmaDTO,
    DmaRaw,
    DmaTransferDTO,
    DmaTransferRaw,
    DmaUpdateDTO,
    DmaUpdateRaw,
    EngineConfigurationSimpleDTO,
    EngineConfigurationSimpleRaw,
    EngineEventDetailDTO,
    EngineEventDetailRaw,
    EngineEventDTO,
    EngineEventRaw,
    EnginesDTO,
    EnginesRaw,
    EnginesUpdateDTO,
    EnginesUpdateRaw,
    KpisDTO,
    KpisRaw,
    KpiTypeDTO,
    KpiTypeRaw,
    NetworkKpisDTO,
    NetworkKpisRaw,
    SensorCreateDTO,
    SensorCreateRaw,
    SensorDetailDTO,
    SensorDetailRaw,
    SensorUpdateDTO,
    SensorUpdateRaw,
    ZoneCreateDTO,
    ZoneCreateRaw,
    ZoneDetailDTO,
    ZoneDetailRaw,
    ZoneDTO,
    ZoneMapDTO,
    ZoneMapRaw,
    ZoneRaw,
    ZoneUpdateDTO,
    ZoneUpdateRaw
} from './dto'
import {
    ComputeTaskDTOFactory,
    EngineEventsDTOFactory,
    EnginesDTOFactory,
    DmaDTOFactory,
    KpiDTOFactory,
    SensorDTOFactory,
    ZoneDTOFactory,
    DmaTaskItemDTOFactory
} from './factories'
import { INetworkAPI } from './INetworkAPI'
import { EngineEventStatus, EngineEventType } from '@/domains/detect/models'
import { Page } from '@/domains/common/models/Page'
import { FeatureCollection } from '@turf/turf'
import { GeoJsonProperties, Geometry } from 'geojson'
import { parseParams } from '@/domains/common/services/ParamParser'
import { DmaTaskOverviewItemRaw } from './dto/DmaTaskOverviewItemRaw'
import { DmaTasksOverviewItemDTOFactory } from './factories/DmaTasksOverviewItemDTOFactory'
import { DmaTaskOverviewItemDTO } from './dto/DmaTaskOverviewItemDTO'
import { DmaTaskItemDTO } from './dto/DmaTaskItemDTO'
import { DmaTaskItemRaw } from './dto/DmaTaskItemRaw'

export type SensorMap = FeatureCollection<Geometry, GeoJsonProperties>

export class NetworkAPI implements INetworkAPI {
    private routes = {
        getZones: () => makeURL(`/data-management/network/zone/`),
        getZone: (zoneId: string) => makeURL(`/data-management/network/zone/${zoneId}/`),
        createZone: () => makeURL(`/data-management/network/zone/`),
        updateZone: (zoneId: string) => makeURL(`/data-management/network/zone/${zoneId}/`),
        getZoneMap: (zoneId: string) => makeURL(`/data-management/network/zone/${zoneId}/map/`),
        getDmas: () => makeURL(`/data-management/network/dma/`),
        getDmasMinimal: () => makeURL(`/data-management/network/dma/minimal/`),
        getDma: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/`),
        getDmaEngineConfiguration: (dmaId: string, configurationId: string) =>
            makeURL(`/data-management/network/dma/${dmaId}/engines/${configurationId}/`),
        createDma: () => makeURL(`/data-management/network/dma/`),
        updateDma: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/`),
        updateDmaConnections: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/connections/`),
        updateDmaEngines: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/engines/`),
        updateOperationalConfiguration: (dmaId: string, configuration_type: string) =>
            makeURL(`/data-management/network/dma/${dmaId}/engines/${configuration_type}/operational/`),
        removeOperationalConfiguration: (dmaId: string, configuration_type: string) =>
            makeURL(`/data-management/network/dma/${dmaId}/engines/${configuration_type}/operational/`),
        getDmaEngineResult: (dmaId: string, engineConfigurationId: string) =>
            makeURL(`/data-management/network/dma/${dmaId}/engines/${engineConfigurationId}/result/`),
        dmaTransfers: () => makeURL(`/data-management/network/dma/transfers/`),
        getDmaBalance: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/balance/`),
        getDmaMeasurements: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/measurements/`),
        createComputeTask: () => makeURL(`/data-management/network/task/`),
        retrieveDmasWithTask: () => makeURL(`/data-management/network/task/per-dma`),
        retrieveTasksForDma: (dmaId: string) => makeURL(`/data-management/network/task/dma/${dmaId}`),

        getSensors: () => makeURL(`/data-management/network/sensor/`),
        getSensor: (sensorId: string) => makeURL(`/data-management/network/sensor/${sensorId}/`),
        createSensor: () => makeURL(`/data-management/network/sensor/`),
        updateSensor: (sensorId: string) => makeURL(`/data-management/network/sensor/${sensorId}/`),
        getSensorsMeasurements: () => makeURL(`/data-management/data/measurements/sensors/`),
        getSensorTypes: () => makeURL(`/data-management/network/sensor/available-types/`),
        getSensorMap: () => makeURL(`/data-management/network/sensor/map/`),

        getKpiTypes: () => makeURL(`/data-management/network/kpi/detect-type/`),
        getKpis: () => makeURL(`/data-management/network/kpi/dma/`),
        getDmaKpis: (dmaId: string) => makeURL(`/data-management/network/dma/${dmaId}/kpis/`),

        getEventsByZone: () => makeURL(`/data-management/network/event/`),
        getEvent: (dmaId: string, configurationId: string, eventId: string) =>
            makeURL(`/data-management/network/dma/${dmaId}/engines/${configurationId}/event/${eventId}/`),
        getEvents: (dmaId: string, configurationId: string) =>
            makeURL(`/data-management/network/dma/${dmaId}/engines/${configurationId}/event/`),
        getAllEvents: () => makeURL(`/data-management/network/event/`),
        getEventsProviderTypes: () => makeURL(`/data-management/network/event/provider-type/`)
    }

    public async getZones(cancelToken?: CancelSource): Promise<ZoneDTO[] | null> {
        let rawZones = null
        try {
            rawZones = await axios.get<ZoneRaw[]>(this.routes.getZones(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getZones(), exception as AxiosError<ServerError>)
        }

        if (!rawZones?.data) {
            return null
        }

        return rawZones.data.map(ZoneDTOFactory.fromRaw)
    }

    public async getZoneDetail(zoneId: string, cancelToken?: CancelSource): Promise<ZoneDetailDTO | null> {
        let rawZone = null
        try {
            rawZone = await axios.get<ZoneDetailRaw>(this.routes.getZone(zoneId), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getZone(zoneId), exception as AxiosError<ServerError>)
        }

        if (!rawZone?.data) {
            return null
        }

        return ZoneDTOFactory.fromDetailRaw(rawZone.data)
    }

    public async createZone(createData: ZoneCreateDTO, cancelToken?: CancelSource): Promise<ZoneDetailDTO | null> {
        let rawZone = null
        try {
            const data: ZoneCreateRaw = ZoneDTOFactory.fromCreateDTO(createData)
            rawZone = await axios.post<ZoneDetailRaw>(this.routes.createZone(), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.createZone(), exception as AxiosError<ServerError>)
        }

        if (!rawZone?.data) {
            return null
        }

        return ZoneDTOFactory.fromDetailRaw(rawZone.data)
    }

    public async updateZoneDetail(
        sensorId: string,
        changes: ZoneUpdateDTO,
        cancelToken?: CancelSource
    ): Promise<ZoneDetailDTO | null> {
        let rawZone = null
        try {
            const data: ZoneUpdateRaw = ZoneDTOFactory.fromUpdateDTO(changes)
            rawZone = await axios.patch<ZoneDetailRaw>(this.routes.updateZone(sensorId), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.updateZone(sensorId), exception as AxiosError<ServerError>)
        }

        if (!rawZone?.data) {
            return null
        }

        return ZoneDTOFactory.fromDetailRaw(rawZone.data)
    }

    public async getZoneMap(zoneId: string | undefined, cancelToken?: CancelSource): Promise<ZoneMapDTO | null> {
        let rawZoneMap = null
        const requestZoneId = zoneId || 'all'
        try {
            rawZoneMap = await axios.get<ZoneMapRaw>(this.routes.getZoneMap(requestZoneId), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getZoneMap(requestZoneId), exception as AxiosError<ServerError>)
        }

        if (!rawZoneMap?.data) {
            return null
        }

        return ZoneDTOFactory.fromZoneMapRaw(rawZoneMap.data)
    }

    public async getDmas(cancelToken?: CancelSource): Promise<DmaDTO[] | null> {
        let rawDmas = null
        try {
            rawDmas = await axios.get<DmaRaw[]>(this.routes.getDmas(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getDmas(), exception as AxiosError<ServerError>)
        }

        if (!rawDmas?.data) {
            return null
        }

        return rawDmas.data.map(DmaDTOFactory.fromRaw)
    }

    public async getDmasMinimal(
        zoneIds: string[] | null = null,
        cancelToken?: CancelSource
    ): Promise<{ [key: string]: string } | null> {
        let rawDmas = null
        try {
            rawDmas = await axios.get<{ [key: string]: string }>(this.routes.getDmasMinimal(), {
                params: zoneIds ? { raw_zone_ids: zoneIds.join(',') } : {},
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getDmasMinimal(), exception as AxiosError<ServerError>)
        }

        if (!rawDmas?.data) {
            return null
        }

        return rawDmas.data
    }

    public async getDmaDetail(dmaId: string, cancelToken?: CancelSource): Promise<DmaDetailDTO | null> {
        let rawDma = null
        try {
            rawDma = await axios.get<DmaDetailRaw>(this.routes.getDma(dmaId), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getDma(dmaId), exception as AxiosError<ServerError>)
        }

        if (!rawDma?.data) {
            return null
        }

        return DmaDTOFactory.fromDetailRaw(rawDma.data)
    }

    public async createDma(createData: DmaCreateDTO, cancelToken?: CancelSource): Promise<DmaDetailDTO | null> {
        let rawDma = null
        try {
            const data: DmaCreateRaw = DmaDTOFactory.fromCreateDTO(createData)
            rawDma = await axios.post<DmaDetailRaw>(this.routes.createDma(), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.createDma(), exception as AxiosError<ServerError>)
        }

        if (!rawDma?.data) {
            return null
        }

        return DmaDTOFactory.fromDetailRaw(rawDma.data)
    }

    public async createComputeTask(
        createData: ComputeDmaBalanceTaskCreateDTO | ComputeEngineConfigurationTaskCreateDTO,
        cancelToken?: CancelSource
    ): Promise<ComputeDmaBalanceTaskDTO | ComputeEngineConfigurationTaskDTO | null> {
        let rawTask = null
        try {
            const data = ComputeTaskDTOFactory.fromCreateDTO(createData)
            if (data) {
                rawTask = await axios.post<ComputeDmaBalanceTaskRaw | ComputeEngineConfigurationTaskRaw>(
                    this.routes.createComputeTask(),
                    data,
                    {
                        cancelToken: cancelToken?.token
                    }
                )
            }
        } catch (exception) {
            this.handleError(this.routes.createComputeTask(), exception as AxiosError<ServerError>)
        }

        if (!rawTask?.data) {
            return null
        }

        return ComputeTaskDTOFactory.fromRaw(rawTask.data)
    }

    public async updateDmaDetail(
        dmaId: string,
        changes: DmaUpdateDTO,
        cancelToken?: CancelSource
    ): Promise<DmaDetailDTO | null> {
        let rawDma = null
        try {
            const data: DmaUpdateRaw = DmaDTOFactory.fromUpdateDTO(changes)
            rawDma = await axios.patch<DmaDetailRaw>(this.routes.updateDma(dmaId), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.updateDma(dmaId), exception as AxiosError<ServerError>)
        }

        if (!rawDma?.data) {
            return null
        }

        return DmaDTOFactory.fromDetailRaw(rawDma.data)
    }

    public async updateDmaConnections(
        dmaId: string,
        connections: ConnectionUpdateDTO[],
        cancelToken?: CancelSource
    ): Promise<ConnectionDTO[] | null> {
        let rawConnections = null
        try {
            const data: ConnectionUpdateRaw[] = connections.map(DmaDTOFactory.fromConnectionUpdateDto)
            rawConnections = await axios.post<ConnectionRaw[]>(this.routes.updateDmaConnections(dmaId), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.updateDmaConnections(dmaId), exception as AxiosError<ServerError>)
        }

        if (!rawConnections?.data) {
            return null
        }

        return rawConnections.data.map(DmaDTOFactory.fromRawConnection)
    }

    public async updateDmaEngines(
        dmaId: string,
        engines: EnginesUpdateDTO,
        cancelToken?: CancelSource
    ): Promise<EnginesDTO | null> {
        let rawEngines = null
        try {
            const data: EnginesUpdateRaw = EnginesDTOFactory.fromUpdateDTO(engines)
            rawEngines = await axios.post<EnginesRaw>(this.routes.updateDmaEngines(dmaId), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.updateDmaEngines(dmaId), exception as AxiosError<ServerError>)
        }

        if (!rawEngines?.data) {
            return null
        }

        return EnginesDTOFactory.fromRaw(rawEngines.data)
    }

    public async updateOperationalConfiguration(
        dmaId: string,
        configurationType: string,
        configurationId: string,
        cancelToken?: CancelSource
    ): Promise<string | null> {
        let resultConfigurationId = null
        try {
            resultConfigurationId = await axios.post<string>(
                this.routes.updateOperationalConfiguration(dmaId, configurationType),
                null,
                {
                    params: {
                        configuration_id: configurationId
                    },
                    cancelToken: cancelToken?.token
                }
            )
        } catch (exception) {
            this.handleError(
                this.routes.updateOperationalConfiguration(dmaId, configurationType),
                exception as AxiosError<ServerError>
            )
        }

        if (!resultConfigurationId?.data) {
            return null
        }

        return resultConfigurationId.data
    }

    public async removeOperationalConfiguration(
        dmaId: string,
        configurationType: string,
        cancelToken?: CancelSource
    ): Promise<boolean> {
        try {
            await axios.delete<string>(this.routes.removeOperationalConfiguration(dmaId, configurationType), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(
                this.routes.removeOperationalConfiguration(dmaId, configurationType),
                exception as AxiosError<ServerError>
            )
            return false
        }

        return true
    }

    public async transferDmas(transfers: DmaTransferDTO[], cancelToken?: CancelSource): Promise<boolean> {
        try {
            const data: DmaTransferRaw[] = transfers.map(DmaDTOFactory.fromTransferDTO)
            await axios.put<EnginesRaw>(this.routes.dmaTransfers(), data, {
                cancelToken: cancelToken?.token
            })
            return true
        } catch (exception) {
            this.handleError(this.routes.dmaTransfers(), exception as AxiosError<ServerError>)
            return false
        }
    }

    public async getDmaBalance(
        dmaId: string,
        start?: DateTime,
        end?: DateTime,
        cancelToken?: CancelSource
    ): Promise<string | null> {
        let result = null
        try {
            result = await axios.get<string>(this.routes.getDmaBalance(dmaId), {
                params: {
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO()
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            const axiosError = exception as AxiosError<ServerError>
            if (axiosError) {
                this.handleError(this.routes.getDmaBalance(dmaId), axiosError)
            }
        }

        if (!result?.data) {
            return null
        }
        return result.data
    }

    public async getDmaMeasurements(
        dmaId: string,
        start?: DateTime,
        end?: DateTime,
        aggregator?: AggregatorDTO,
        cancelToken?: CancelSource
    ): Promise<string | null> {
        let result = null
        try {
            result = await axios.get<string>(this.routes.getDmaMeasurements(dmaId), {
                params: {
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO(),
                    window: aggregator?.window,
                    method: aggregator?.method
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getDmaMeasurements(dmaId), exception as AxiosError<ServerError>)
        }

        if (!result?.data) {
            return null
        }
        return result.data
    }

    public async getDmaEngineResult(
        dmaId: string,
        engineConfigurationId: string,
        start?: DateTime,
        end?: DateTime,
        cancelToken?: CancelSource
    ): Promise<string | null> {
        let result = null
        try {
            result = await axios.get<string>(this.routes.getDmaEngineResult(dmaId, engineConfigurationId), {
                params: {
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO()
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(
                this.routes.getDmaEngineResult(dmaId, engineConfigurationId),
                exception as AxiosError<ServerError>
            )
        }

        if (!result?.data) {
            return null
        }
        return result.data
    }

    public async getDmaEngineConfiguration(
        dmaId: string,
        configurationId: string,
        cancelToken?: CancelSource
    ): Promise<EngineConfigurationSimpleDTO | null> {
        let rawDma = null
        try {
            rawDma = await axios.get<EngineConfigurationSimpleRaw>(
                this.routes.getDmaEngineConfiguration(dmaId, configurationId),
                {
                    cancelToken: cancelToken?.token
                }
            )
        } catch (exception) {
            this.handleError(this.routes.getDma(dmaId), exception as AxiosError<ServerError>)
        }

        if (!rawDma?.data) {
            return null
        }

        return EnginesDTOFactory.fromConfigurationSimpleRaw(rawDma.data)
    }

    public async getSensors(cancelToken?: CancelSource): Promise<SensorDetailDTO[] | null> {
        let rawSensors = null
        try {
            rawSensors = await axios.get<SensorDetailRaw[]>(this.routes.getSensors(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getSensors(), exception as AxiosError<ServerError>)
        }

        if (!rawSensors?.data) {
            return null
        }

        return rawSensors.data.map(SensorDTOFactory.fromRaw)
    }

    public async getSensorDetail(sensorId: string, cancelToken?: CancelSource): Promise<SensorDetailDTO | null> {
        let rawSensor = null
        try {
            rawSensor = await axios.get<SensorDetailRaw>(this.routes.getSensor(sensorId), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getSensor(sensorId), exception as AxiosError<ServerError>)
        }

        if (!rawSensor?.data) {
            return null
        }

        return SensorDTOFactory.fromRaw(rawSensor.data)
    }

    public async createSensor(
        createData: SensorCreateDTO,
        cancelToken?: CancelSource
    ): Promise<SensorDetailDTO | null> {
        let rawSensor = null
        try {
            const data: SensorCreateRaw = SensorDTOFactory.fromCreateDTO(createData)
            rawSensor = await axios.post<SensorDetailRaw>(this.routes.createSensor(), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.createSensor(), exception as AxiosError<ServerError>)
        }

        if (!rawSensor?.data) {
            return null
        }

        return SensorDTOFactory.fromRaw(rawSensor.data)
    }

    public async updateSensorDetail(
        sensorId: string,
        changes: SensorUpdateDTO,
        cancelToken?: CancelSource
    ): Promise<SensorDetailDTO | null> {
        let rawSensor = null
        try {
            const data: SensorUpdateRaw = SensorDTOFactory.fromUpdateDTO(changes)
            rawSensor = await axios.patch<SensorDetailRaw>(this.routes.updateSensor(sensorId), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.updateSensor(sensorId), exception as AxiosError<ServerError>)
        }

        if (!rawSensor?.data) {
            return null
        }

        return SensorDTOFactory.fromRaw(rawSensor.data)
    }

    public async getSensorMeasurements(
        sensorId: string,
        fields?: string[],
        start?: DateTime,
        end?: DateTime,
        aggregator?: AggregatorDTO,
        cancelToken?: CancelSource
    ): Promise<string | null> {
        let result = null
        try {
            const data = {
                sensors: [sensorId]
            }
            result = await axios.post<string>(this.routes.getSensorsMeasurements(), data, {
                params: {
                    start: start?.toUTC().toISO(),
                    fields: fields,
                    end: end?.toUTC().toISO(),
                    window: aggregator?.window,
                    method: aggregator?.method
                },
                paramsSerializer: parseParams,
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getSensorsMeasurements(), exception as AxiosError<ServerError>)
        }

        if (!result?.data) {
            return null
        }
        return result.data
    }

    public async getSensorTypes(cancelToken?: CancelTokenSource | undefined): Promise<string[] | null> {
        let result = null
        try {
            result = await axios.get<string[]>(this.routes.getSensorTypes(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getSensorTypes(), exception as AxiosError<ServerError>)
        }

        if (!result?.data) {
            return null
        }
        return result.data
    }

    public async getSensorMap(cancelToken?: CancelTokenSource | undefined): Promise<SensorMap | null> {
        let result = null
        try {
            result = await axios.get<SensorMap>(this.routes.getSensorMap(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getSensorMap(), exception as AxiosError<ServerError>)
        }

        if (!result?.data) {
            return null
        }
        return result.data
    }

    public async getKpiTypes(cancelToken?: CancelSource): Promise<KpiTypeDTO[] | null> {
        let rawKpiTypes = null

        try {
            rawKpiTypes = await axios.get<KpiTypeRaw[]>(this.routes.getKpiTypes(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getKpiTypes(), exception as AxiosError<ServerError>)
        }

        if (!rawKpiTypes?.data) {
            return null
        }

        return rawKpiTypes.data.map(KpiDTOFactory.fromKpiTypeRaw)
    }

    public async getKpis(cancelToken?: CancelSource): Promise<NetworkKpisDTO[] | null> {
        let rawKpis = null
        try {
            rawKpis = await axios.get<NetworkKpisRaw[]>(this.routes.getKpis(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getKpis(), exception as AxiosError<ServerError>)
        }

        if (!rawKpis?.data) {
            return null
        }

        return rawKpis.data.map(KpiDTOFactory.fromRaw)
    }

    public async getDmaKpis(dmaId: string, cancelToken?: CancelSource): Promise<KpisDTO | null> {
        let rawKpis = null
        try {
            rawKpis = await axios.get<KpisRaw>(this.routes.getDmaKpis(dmaId), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getDmaKpis(dmaId), exception as AxiosError<ServerError>)
        }

        if (!rawKpis?.data) {
            return null
        }

        return KpiDTOFactory.fromKpisRaw(rawKpis.data)
    }

    public async getEvent(
        dmaId: string,
        configurationId: string,
        eventId: string,
        start?: DateTime,
        end?: DateTime,
        cancelToken?: CancelSource
    ): Promise<EngineEventDetailDTO | null> {
        let rawEvent = null
        const route = this.routes.getEvent(dmaId, configurationId, eventId)

        try {
            rawEvent = await axios.get<EngineEventDetailRaw>(route, {
                params: {
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO()
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(route, exception as AxiosError<ServerError>)
        }

        if (!rawEvent?.data) {
            return null
        }

        return EngineEventsDTOFactory.fromDetailRaw(rawEvent.data)
    }

    public async getEvents(
        dmaId: string,
        configurationId: string,
        start?: DateTime,
        end?: DateTime,
        cancelToken?: CancelSource
    ): Promise<EngineEventDTO[] | null> {
        let rawEvents = null

        try {
            rawEvents = await axios.get<EngineEventRaw[]>(this.routes.getEvents(dmaId, configurationId), {
                params: {
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO()
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getEvents(dmaId, configurationId), exception as AxiosError<ServerError>)
        }

        if (!rawEvents?.data) {
            return null
        }

        return rawEvents.data.map(EngineEventsDTOFactory.fromRaw)
    }

    public async getAllEvents(
        zoneIds: string[],
        dmaIds: string[],
        types: EngineEventType[],
        statuses: EngineEventStatus[],
        providerTypes: string[],
        sortBy: string,
        sortDirection: number,
        limit: number,
        skip: number,
        start?: DateTime | undefined,
        end?: DateTime | undefined,
        cancelToken?: CancelTokenSource | undefined
    ): Promise<Page<EngineEventDTO> | null> {
        let rawEvents = null

        try {
            rawEvents = await axios.get<Page<EngineEventRaw>>(this.routes.getAllEvents(), {
                params: {
                    zone_ids: zoneIds,
                    dma_ids: dmaIds,
                    types: types,
                    statuses: statuses,
                    provider_types: providerTypes,
                    sort_by: sortBy,
                    sort_direction: sortDirection,
                    limit: limit,
                    skip: skip,
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO()
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getEventsByZone(), exception as AxiosError<ServerError>)
        }

        if (!rawEvents?.data) {
            return null
        }

        return new Page<EngineEventDTO>(
            rawEvents.data.content.map(EngineEventsDTOFactory.fromRaw),
            rawEvents.data.count
        )
    }

    public async getEventsProviderTypes(cancelToken?: CancelTokenSource | undefined): Promise<string[] | null> {
        let rawProviderTypes = null

        try {
            rawProviderTypes = await axios.get<string[]>(this.routes.getEventsProviderTypes(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getEventsProviderTypes(), exception as AxiosError<ServerError>)
        }

        if (!rawProviderTypes?.data) {
            return null
        }

        return rawProviderTypes.data
    }

    public async getEventsByZone(
        zoneIds: string[],
        start?: DateTime,
        end?: DateTime,
        cancelToken?: CancelSource
    ): Promise<EngineEventDTO[] | null> {
        let rawEvents = null

        try {
            rawEvents = await axios.post<EngineEventRaw[]>(this.routes.getEventsByZone(), zoneIds, {
                params: {
                    start: start?.toUTC().toISO(),
                    end: end?.toUTC().toISO()
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getEventsByZone(), exception as AxiosError<ServerError>)
        }

        if (!rawEvents?.data) {
            return null
        }

        return rawEvents.data.map(EngineEventsDTOFactory.fromRaw)
    }

    public async retrieveDmasWithTask(
        name: string,
        sortBy: string,
        sortDirection: number,
        limit: number,
        skip: number,
        cancelToken?: CancelSource
    ): Promise<Page<DmaTaskOverviewItemDTO> | null> {
        let rawDmasWithTasks = null
        try {
            rawDmasWithTasks = await axios.get<Page<DmaTaskOverviewItemRaw>>(this.routes.retrieveDmasWithTask(), {
                params: {
                    name: name,
                    sort_by: sortBy,
                    sort_direction: sortDirection,
                    limit: limit,
                    skip: skip
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.retrieveDmasWithTask(), exception as AxiosError<ServerError>)
        }
        if (!rawDmasWithTasks?.data) {
            return null
        }
        return new Page<DmaTaskOverviewItemDTO>(
            rawDmasWithTasks.data.content.map(DmaTasksOverviewItemDTOFactory.fromRaw),
            rawDmasWithTasks.data.count
        )
    }

    public async retrieveTasksForDma(
        dmaId: string,
        sortBy: string,
        sortDirection: number,
        limit: number,
        skip: number,
        cancelToken?: CancelSource
    ): Promise<Page<DmaTaskItemDTO> | null> {
        let rawDmaTasks = null
        try {
            rawDmaTasks = await axios.get<Page<DmaTaskItemRaw>>(this.routes.retrieveTasksForDma(dmaId), {
                params: {
                    sort_by: sortBy,
                    sort_direction: sortDirection,
                    limit: limit,
                    skip: skip
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.retrieveTasksForDma(dmaId), exception as AxiosError<ServerError>)
        }
        if (!rawDmaTasks?.data) {
            return null
        }
        return new Page<DmaTaskItemDTO>(
            rawDmaTasks.data.content.map(DmaTaskItemDTOFactory.fromRaw),
            rawDmaTasks.data.count
        )
    }

    private handleError(url: string, error: AxiosError<ServerError>): void {
        if (axios.isCancel(error)) {
            return
        }

        if (error.response && error.response.status === 500) {
            //Server sent error
            console.error(url)
            console.error(error)
        } else if (error.request) {
            // request sent but no answer received
        }
    }
}
