import { SensorDetailDTO } from './SensorDetailDTO'

export enum ConnectionDirectionDTO {
    INPUT = 'input',
    OUTPUT = 'output'
}

export class ConnectionDTO {
    constructor(readonly sensor: SensorDetailDTO, readonly direction: ConnectionDirectionDTO) {}
}
