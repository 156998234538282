export class PreferencesDTO {
    constructor(readonly language: string | null) {}
}

export class PreferencesUpdateDTO {
    readonly language?: string | null

    constructor(base: Partial<PreferencesUpdateDTO>) {
        this.language = base.language
    }
}
