import { RawLocation, RouteConfig } from 'vue-router'

const SettingsView = (): Promise<typeof import('*.vue')> => import('../containers/SettingsView.vue')
const OrganizationSettings = (): Promise<typeof import('*.vue')> => import('../containers/OrganizationSettings.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'organization',
        name: 'OrganizationSettings',
        component: OrganizationSettings
    },
    {
        path: '',
        redirect: (): RawLocation => {
            return { name: 'OrganizationSettings' }
        }
    }
]

export const container = SettingsView
