import { EngineConfigurationUpdateDTO } from './EngineConfigurationUpdateDTO'

export class EnginesUpdateDTO {
    constructor(
        readonly detect: EngineConfigurationUpdateDTO[],
        readonly monitor: EngineConfigurationUpdateDTO[],
        readonly quality: EngineConfigurationUpdateDTO[],
        readonly detectOperationalConfigurationId: string | null,
        readonly monitorOperationalConfigurationId: string | null,
        readonly qualityOperationalConfigurationId: string | null
    ) {}
}
