import { EngineEventBaseDTO, EngineEventTypeDTO } from './EngineEventBaseDTO'
import { EngineEventDataPointDTO } from './EngineEventDataPointDTO'

export class EngineEventDetailDTO extends EngineEventBaseDTO {
    constructor(
        id: string,
        dmaId: string,
        dmaName: string,
        dmaExternalId: string,
        configurationId: string,
        providerType: string,
        type: EngineEventTypeDTO,
        createdOn: string,
        lastUpdatedOn: string,
        readonly dataPoints: EngineEventDataPointDTO[],
        firstTimestamp?: string,
        lastTimestamp?: string
    ) {
        super(
            id,
            dmaId,
            dmaName,
            dmaExternalId,
            configurationId,
            providerType,
            type,
            createdOn,
            lastUpdatedOn,
            firstTimestamp,
            lastTimestamp
        )
    }
}
