import {
    EngineConfigurationDTO,
    EngineConfigurationRaw,
    EngineConfigurationSimpleDTO,
    EngineConfigurationSimpleRaw,
    EngineConfigurationUpdateDTO,
    EngineConfigurationUpdateRaw,
    EnginesDTO,
    EnginesRaw,
    EnginesUpdateDTO,
    EnginesUpdateRaw
} from '../dto'

export abstract class EnginesDTOFactory {
    static fromRaw(enginesRaw: EnginesRaw): EnginesDTO {
        return new EnginesDTO(
            enginesRaw.detect ? enginesRaw.detect.map(EnginesDTOFactory.fromConfigurationRaw) : [],
            enginesRaw.monitor ? enginesRaw.monitor.map(EnginesDTOFactory.fromConfigurationRaw) : [],
            enginesRaw.quality ? enginesRaw.quality.map(EnginesDTOFactory.fromConfigurationRaw) : [],
            enginesRaw.detect_operational_configuration_id ? enginesRaw.detect_operational_configuration_id : null,
            enginesRaw.monitor_operational_configuration_id ? enginesRaw.monitor_operational_configuration_id : null,
            enginesRaw.quality_operational_configuration_id ? enginesRaw.quality_operational_configuration_id : null
        )
    }

    static fromConfigurationRaw(raw: EngineConfigurationRaw): EngineConfigurationDTO {
        return new EngineConfigurationDTO(
            raw.id,
            raw.provider_type,
            raw.name,
            raw.earliest_computable_date,
            raw.active,
            raw.settings,
            raw.last_result
        )
    }

    static fromConfigurationSimpleRaw(raw: EngineConfigurationSimpleRaw): EngineConfigurationSimpleDTO {
        return new EngineConfigurationSimpleDTO(raw.id, raw.provider_type, raw.name, raw.active)
    }

    static fromUpdateDTO(dto: EnginesUpdateDTO): EnginesUpdateRaw {
        return {
            detect: dto.detect.map(EnginesDTOFactory.fromConfigurationUpdateDTO),
            monitor: dto.monitor.map(EnginesDTOFactory.fromConfigurationUpdateDTO),
            quality: dto.quality.map(EnginesDTOFactory.fromConfigurationUpdateDTO),
            detect_operational_configuration_id: dto.detectOperationalConfigurationId,
            monitor_operational_configuration_id: dto.monitorOperationalConfigurationId,
            quality_operational_configuration_id: dto.qualityOperationalConfigurationId
        }
    }

    static fromConfigurationUpdateDTO(dto: EngineConfigurationUpdateDTO): EngineConfigurationUpdateRaw {
        return {
            id: dto.id,
            active: dto.active,
            name: dto.name,
            earliest_computable_date: dto.earliestComputableDate,
            provider_type: dto.providerType,
            settings: dto.settings,
            last_result: dto.lastResult
        }
    }
}
