import { DmaTaskItemDTO, DmaTaskItemRaw } from '../dto'
import { TaskStatusDTOFactory } from './TaskStatusDTOFactory'
import { UserDTOFactory } from './UserDTOFactory'

export abstract class DmaTaskItemDTOFactory {
    static fromRaw(taskRaw: DmaTaskItemRaw): DmaTaskItemDTO {
        return new DmaTaskItemDTO(
            taskRaw.id,
            TaskStatusDTOFactory.fromRaw(taskRaw.status),
            taskRaw.created_on,
            taskRaw.last_update,
            taskRaw.created_by ? UserDTOFactory.fromRaw(taskRaw.created_by) : null,
            taskRaw.report,
            taskRaw.provider_type
        )
    }
}
