import {
    EngineEventDataPointDTO,
    EngineEventDataPointRaw,
    EngineEventDetailDTO,
    EngineEventDetailRaw,
    EngineEventDTO,
    EngineEventRaw,
    EngineEventStatusDTO,
    EngineEventStatusRaw,
    EngineEventTypeDTO,
    EngineEventTypeRaw
} from '../dto'
import { roundNumber } from '@/domains/common/utils/converters'

export abstract class EngineEventsDTOFactory {
    static fromDetailRaw(event: EngineEventDetailRaw): EngineEventDetailDTO {
        return new EngineEventDetailDTO(
            event.id,
            event.dma_id,
            event.dma_name,
            event.dma_external_id,
            event.configuration_id,
            event.provider_type,
            EngineEventsDTOFactory.typeRawToDTOMappings[event.type],
            event.created_on,
            event.last_updated_on,
            event.data_points.map(EngineEventsDTOFactory.fromDataPointRaw),
            event.first_timestamp,
            event.last_timestamp
        )
    }

    static fromRaw(event: EngineEventRaw): EngineEventDTO {
        return new EngineEventDTO(
            event.id,
            event.dma_id,
            event.dma_name,
            event.dma_external_id,
            event.configuration_id,
            event.provider_type,
            EngineEventsDTOFactory.typeRawToDTOMappings[event.type],
            event.created_on,
            event.last_updated_on,
            event.last_info ? EngineEventsDTOFactory.fromDataPointRaw(event.last_info) : undefined,
            event.first_timestamp,
            event.last_timestamp
        )
    }

    static fromDataPointRaw(dataPoint: EngineEventDataPointRaw): EngineEventDataPointDTO {
        const values = dataPoint.values
        values['loss_flow'] = roundNumber(
            dataPoint.values['loss_flow'] ? (dataPoint.values['loss_flow'] as number) : null,
            1
        )
        values['loss_volume'] = roundNumber(
            dataPoint.values['loss_volume'] ? (dataPoint.values['loss_volume'] as number) : null,
            0
        )
        return new EngineEventDataPointDTO(
            dataPoint.timestamp,
            values,
            EngineEventsDTOFactory.statusRawToDTOMappings[dataPoint.status],
            dataPoint.level,
            dataPoint.start,
            dataPoint.end
        )
    }

    private static typeRawToDTOMappings: Record<EngineEventTypeRaw, EngineEventTypeDTO> = {
        'Bad Data Quality': EngineEventTypeDTO.BAD_DATA_QUALITY,
        Burst: EngineEventTypeDTO.BURST,
        'Cumulative Leak': EngineEventTypeDTO.CUMULATIVE_LEAK,
        'Low Leak': EngineEventTypeDTO.LOW,
        'Medium Leak': EngineEventTypeDTO.MEDIUM
    }

    private static statusRawToDTOMappings: Record<EngineEventStatusRaw, EngineEventStatusDTO> = {
        Active: EngineEventStatusDTO.ACTIVE,
        Cancelled: EngineEventStatusDTO.CANCELLED,
        Finished: EngineEventStatusDTO.FINISHED,
        Pending: EngineEventStatusDTO.PENDING,
        Terminating: EngineEventStatusDTO.TERMINATING
    }
}
