import { ScopeRoleDTO, ScopeDTO, UserDTO, UserUpdateDTO, ScopeUpdateDTO } from '@/api/user'
import { PreferencesDTO } from '@/api/user/dto/PreferencesDTO'
import { Preferences, RoleEnum, Scope, ScopeUpdate, User, UserUpdate } from '@/domains/common/models'
import { OrganizationFactory } from './OrganizationFactory'

export abstract class UserFactory {
    static fromDTO(dto: UserDTO): User {
        return new User(
            dto.id || '',
            dto.firstName || '',
            dto.lastName || '',
            dto.email || '',
            dto.preferences ? this.fromPreferencesDTO(dto.preferences) : Preferences.default(),
            dto.scopes ? dto.scopes.map(UserFactory.fromScopeDTO) : []
        )
    }

    private static fromPreferencesDTO(dto: PreferencesDTO): Preferences {
        return new Preferences(dto.language)
    }

    static fromScopeDTO(dto: ScopeDTO): Scope {
        return new Scope(OrganizationFactory.fromDTO(dto.organization), UserFactory.fromScopeRoleDTO(dto.role))
    }

    static fromScopeRoleDTO(dto: ScopeRoleDTO): RoleEnum {
        return UserFactory.roleDTOToModelMappings[dto] || RoleEnum.OPERATOR
    }

    private static roleDTOToModelMappings: Record<ScopeRoleDTO, RoleEnum> = {
        [ScopeRoleDTO.MASTER_ADMINISTRATOR]: RoleEnum.MASTER_ADMINISTRATOR,
        [ScopeRoleDTO.ORGANIZATION_ADMINISTRATOR]: RoleEnum.ORGANIZATION_ADMINISTRATOR,
        [ScopeRoleDTO.MANAGER]: RoleEnum.MANAGER,
        [ScopeRoleDTO.OPERATOR]: RoleEnum.OPERATOR
    }

    static fromUpdateModel(model: UserUpdate): UserUpdateDTO {
        return new UserUpdateDTO({
            username: model.username,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            preferences: model.preferences ? UserFactory.fromPreferences(model.preferences) : undefined,
            newPassword: model.newPassword,
            confirmPassword: model.confirmPassword,
            currentPassword: model.currentPassword,
            scopes: model.scopes ? model.scopes.map(UserFactory.fromScopeUpdate) : undefined
        })
    }

    private static fromPreferences(model: Partial<Preferences>): PreferencesDTO {
        return new PreferencesDTO(model.language ?? null)
    }

    private static fromScopeUpdate(model: ScopeUpdate): ScopeUpdateDTO {
        return new ScopeUpdateDTO(model.organization_id, UserFactory.fromScopeRoleUpdate(model.role))
    }

    private static fromScopeRoleUpdate(model: RoleEnum): ScopeRoleDTO {
        return UserFactory.roleModeltoDTOMappings[model] || RoleEnum.OPERATOR
    }

    private static roleModeltoDTOMappings: Record<RoleEnum, ScopeRoleDTO> = {
        [RoleEnum.MASTER_ADMINISTRATOR]: ScopeRoleDTO.MASTER_ADMINISTRATOR,
        [RoleEnum.ORGANIZATION_ADMINISTRATOR]: ScopeRoleDTO.ORGANIZATION_ADMINISTRATOR,
        [RoleEnum.MANAGER]: ScopeRoleDTO.MANAGER,
        [RoleEnum.OPERATOR]: ScopeRoleDTO.OPERATOR
    }
}
