import { Point } from 'geojson'

export enum SensorTypeDTO {
    FLOW = 'FLOW',
    PRESSURE = 'PRESSURE',
    FLOW_PRESSURE = 'FLOW_PRESSURE'
}

export class SensorCreateDTO {
    constructor(
        readonly externalId: string,
        readonly name: string,
        readonly type: SensorTypeDTO,
        readonly timezone: string,
        readonly position: Point,
    ) {}
}
