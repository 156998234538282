export class Page<T> {
    private _content: T[]
    private _count: number

    constructor(content: T[], count: number) {
        this._content = content
        this._count = count
    }

    public get content(): T[] {
        return this._content
    }

    public get count(): number {
        return this._count
    }
}
