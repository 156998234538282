import { KpiDTOFactory } from '@/api/data-management/network'
import { SettingsDTO, SettingsRaw } from '@/api/organization'

export abstract class SettingsDTOFactory {
    public static fromRaw(raw: SettingsRaw): SettingsDTO {
        return new SettingsDTO(KpiDTOFactory.fromKpiTypeRaw(raw.detect_overview_map_default_kpi))
    }

    public static fromDTO(dto: Partial<SettingsDTO>): Partial<SettingsRaw> {
        return {
            detect_overview_map_default_kpi: dto.detectOverviewMapDefaultKpi
                ? KpiDTOFactory.fromKpiTypeDTO(dto.detectOverviewMapDefaultKpi)
                : undefined
        }
    }
}
