import { ApplicationDTOFactory } from '@/api/application'
import { OrganizationDTO, SettingsDTOFactory, OrganizationRaw } from '@/api/organization'
import { ScopeDTO, ScopeRaw, ScopeRoleRaw, ScopeUpdateDTO, ScopeUpdateRaw } from '@/api/user'
import { ScopeRoleDTO } from '@/api/user/dto/ScopeDTO'

export abstract class ScopeDTOFactory {
    static fromRaw(raw: ScopeRaw): ScopeDTO {
        return new ScopeDTO(
            ScopeDTOFactory.fromOrganizationRaw(raw.organization),
            ScopeDTOFactory.fromRoleRaw(raw.role)
        )
    }

    static fromOrganizationRaw(raw: OrganizationRaw): OrganizationDTO {
        return new OrganizationDTO(
            raw.id,
            raw.name,
            raw.slug,
            raw.applications.map(ApplicationDTOFactory.fromRaw),
            raw.domain,
            SettingsDTOFactory.fromRaw(raw.settings)
        )
    }

    static fromRoleRaw(raw: ScopeRoleRaw): ScopeRoleDTO {
        return ScopeDTOFactory.roleRawToDTOMappings[raw] || ScopeRoleDTO.OPERATOR
    }

    static roleRawToDTOMappings: Record<ScopeRoleRaw, ScopeRoleDTO> = {
        'master-administrator': ScopeRoleDTO.MASTER_ADMINISTRATOR,
        'organization-administrator': ScopeRoleDTO.ORGANIZATION_ADMINISTRATOR,
        manager: ScopeRoleDTO.MANAGER,
        operator: ScopeRoleDTO.OPERATOR
    }

    static fromUpdateDTO(dto: ScopeUpdateDTO): ScopeUpdateRaw {
        return {
            organization_id: dto.organization_id,
            role: ScopeDTOFactory.fromRoleDTO(dto.role)
        }
    }

    static fromRoleDTO(dto: ScopeRoleDTO): ScopeRoleRaw {
        return ScopeDTOFactory.roleDTOToRawMappings[dto] || 'operator'
    }

    static roleDTOToRawMappings: Record<ScopeRoleDTO, ScopeRoleRaw> = {
        [ScopeRoleDTO.MASTER_ADMINISTRATOR]: 'master-administrator',
        [ScopeRoleDTO.ORGANIZATION_ADMINISTRATOR]: 'organization-administrator',
        [ScopeRoleDTO.MANAGER]: 'manager',
        [ScopeRoleDTO.OPERATOR]: 'operator'
    }
}
