import { invert } from 'lodash'
import {
    DetectKpisDTO,
    KpisDTO,
    KpisRaw,
    KpiTypeDTO,
    KpiTypeRaw,
    NetworkKpisDTO,
    NetworkKpisRaw,
    QualityKpisDTO
} from '../dto'

export abstract class KpiDTOFactory {
    static fromRaw(raw: NetworkKpisRaw): NetworkKpisDTO {
        return new NetworkKpisDTO(raw.dma_id, KpiDTOFactory.fromKpisRaw(raw.kpis))
    }

    static fromKpisRaw(raw: KpisRaw): KpisDTO {
        return new KpisDTO(
            raw.detect
                ? new DetectKpisDTO(
                      raw.detect.provider_type,
                      raw.detect.timestamp,
                      raw.detect.event_type,
                      raw.detect.alert_level,
                      raw.detect.loss_flow,
                      raw.detect.flow_per_house_connection,
                      raw.detect.flow_per_pipe_length,
                      raw.detect.loss_flow_minimum_night_flow,
                      raw.detect.flow_per_house_connection_minimum_night_flow,
                      raw.detect.flow_per_pipe_length_minimum_night_flow,
                      raw.detect.loss_volume,
                      raw.detect.mnf
                  )
                : null,
            raw.quality
                ? new QualityKpisDTO(
                      raw.quality.provider_type,
                      raw.quality.timestamp,
                      raw.quality.positive_night_flow,
                      raw.quality.missing_data
                  )
                : null
        )
    }

    private static kpiTypeRawToDTOMappings: Record<KpiTypeRaw, KpiTypeDTO> = {
        'Alert Level': KpiTypeDTO.ALERT_LEVEL,
        'Loss Flow': KpiTypeDTO.LOSS_FLOW,
        'Flow Per House Connection': KpiTypeDTO.FLOW_PER_HOUSE_CONNECTION,
        'Flow Per Pipe Length': KpiTypeDTO.FLOW_PER_PIPE_LENGTH
    }

    static fromKpiTypeRaw(raw: KpiTypeRaw): KpiTypeDTO {
        return KpiDTOFactory.kpiTypeRawToDTOMappings[raw] || KpiTypeDTO.ALERT_LEVEL
    }

    static fromKpiTypeDTO(dto: KpiTypeDTO): KpiTypeRaw {
        return (invert(KpiDTOFactory.kpiTypeRawToDTOMappings)[dto] as KpiTypeDTO) || 'Alert Level'
    }
}
