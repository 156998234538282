import {
    ZoneCreateDTO,
    ZoneCreateRaw,
    ZoneDetailDTO,
    ZoneDetailRaw,
    ZoneDTO,
    ZoneMapDTO,
    ZoneMapRaw,
    ZoneRaw,
    ZoneUpdateDTO,
    ZoneUpdateRaw
} from '../dto'
import { DmaDTOFactory } from './DmaDTOFactory'

export abstract class ZoneDTOFactory {
    static fromRaw(zone: ZoneRaw): ZoneDTO {
        return new ZoneDTO(zone.id, zone.name)
    }

    static fromDetailRaw(zone: ZoneDetailRaw): ZoneDetailDTO {
        return new ZoneDetailDTO(zone.id, zone.name, zone.dmas ? zone.dmas.map(DmaDTOFactory.fromRaw) : [])
    }

    static fromCreateDTO(dto: ZoneCreateDTO): ZoneCreateRaw {
        return {
            name: dto.name
        }
    }

    static fromUpdateDTO(dto: ZoneUpdateDTO): ZoneUpdateRaw {
        return {
            name: dto.name
        }
    }

    static fromZoneMapRaw(zoneMap: ZoneMapRaw): ZoneMapDTO {
        return new ZoneMapDTO(zoneMap.dmas, zoneMap.sensors)
    }
}
