export class Application {
    private _id: string
    private _providerType: string
    private _instanceName: string
    private _organizationId: string
    private _type: string

    constructor(id: string, providerType: string, instanceName: string, organizationId: string, type: string) {
        this._id = id
        this._providerType = providerType
        this._instanceName = instanceName
        this._organizationId = organizationId
        this._type = type
    }

    public get id(): string {
        return this._id
    }

    public get providerType(): string {
        return this._providerType
    }

    public get instanceName(): string {
        return this._instanceName
    }

    public get organizationId(): string {
        return this._organizationId
    }

    public get type(): string {
        return this._type
    }
}
