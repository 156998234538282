import { KpiType } from './enums/KpiType'

export class Settings {
    private _detectOverviewMapDefaultKpi: KpiType | null

    constructor(detectOverviewMapDefaultKpi: KpiType | null) {
        this._detectOverviewMapDefaultKpi = detectOverviewMapDefaultKpi
    }

    static default(): Settings {
        return new Settings(null)
    }

    public get detectOverviewMapDefaultKpi(): KpiType | null {
        return this._detectOverviewMapDefaultKpi
    }
}
