import axios, { AxiosError } from 'axios'

import { CancelSource, makeURL } from '@/api/config'
import { ServerError } from '@/api/errors/ServerError'

import {
    IOrganizationAPI,
    OrganizationDTO,
    OrganizationDTOFactory,
    OrganizationRaw,
    OrganizationUpdateDTO,
    OrganizationUpdateRaw
} from '@/api/organization'

export class OrganizationAPI implements IOrganizationAPI {
    private routes = {
        getBySlug: (organizationSlug: string) => makeURL(`/account/organizations/${organizationSlug}/`),
        update: (organizationId: string) => makeURL(`/account/organizations/${organizationId}/`)
    }

    public async getBySlug(organizationSlug: string, cancelToken?: CancelSource): Promise<OrganizationDTO | null> {
        let raw = null
        try {
            raw = await axios.get<OrganizationRaw>(this.routes.getBySlug(organizationSlug), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getBySlug(organizationSlug), exception as AxiosError<ServerError>)
        }

        if (!raw?.data) {
            return null
        }

        return OrganizationDTOFactory.fromRaw(raw.data)
    }

    public async update(
        organizationId: string,
        changes: OrganizationUpdateDTO,
        cancelToken?: CancelSource
    ): Promise<OrganizationDTO | null> {
        let raw = null
        try {
            const data: Partial<OrganizationUpdateRaw> = OrganizationDTOFactory.fromUpdateDTO(changes)
            raw = await axios.patch<OrganizationRaw>(this.routes.update(organizationId), data, {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.update(organizationId), exception as AxiosError<ServerError>)
        }

        if (!raw?.data) {
            return null
        }

        return OrganizationDTOFactory.fromRaw(raw.data)
    }

    handleError(url: string, error: AxiosError<ServerError>): void {
        if (error.request.status === 500) {
            console.error(url)
            console.error(error)
        }
    }
}
