import { ApplicationDTOFactory } from '@/api/application'
import {
    OrganizationDTO,
    OrganizationRaw,
    OrganizationUpdateDTO,
    OrganizationUpdateRaw,
    SettingsDTOFactory
} from '@/api/organization'

export abstract class OrganizationDTOFactory {
    public static fromRaw(raw: OrganizationRaw): OrganizationDTO {
        return new OrganizationDTO(
            raw.id,
            raw.name,
            raw.slug,
            raw.applications.map(ApplicationDTOFactory.fromRaw),
            raw.domain,
            SettingsDTOFactory.fromRaw(raw.settings)
        )
    }

    public static fromUpdateDTO(dto: OrganizationUpdateDTO): Partial<OrganizationUpdateRaw> {
        return {
            settings: dto.settings ? SettingsDTOFactory.fromDTO(dto.settings) : undefined
        }
    }
}
