import { Point } from 'geojson'
import { SensorTypeDTO } from './SensorCreateDTO'

export class SensorDetailDTO {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly type: SensorTypeDTO,
        readonly externalId: string,
        readonly timezone: string,
        readonly position: Point
    ) {}
}
