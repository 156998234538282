import { Geometry } from 'geojson'

export class DmaUpdateDTO {
    constructor(
        readonly zoneId: string | undefined,
        readonly externalId: string | undefined,
        readonly name: string | undefined,
        readonly description: string | undefined,
        readonly timezone: string | undefined,
        readonly active: boolean | undefined,
        readonly pipeLength: number | undefined,
        readonly timeStep: number | undefined,
        readonly averageWindow: number | undefined,
        readonly gracePeriod: number | undefined,
        readonly inhabitantsCount: number | null | undefined,
        readonly houseConnections: number | null | undefined,
        readonly averagePressure: number | null | undefined,
        readonly nightflowStartHour: Date | undefined,
        readonly nightflowEndHour: Date | undefined,
        readonly minimumNightFlow: number | undefined,
        readonly invoicedWaterVolumePerYear: number | undefined,
        readonly geometry: Geometry | null | undefined
    ) {}
}
