import { Application } from './Application'
import { Settings } from './Settings'

export class Organization {
    private _id: string
    private _name: string
    private _slug: string
    private _applications: Application[]
    private _domain: string
    private _settings: Settings

    constructor(
        id: string,
        name: string,
        slug: string,
        applications: Application[],
        domain: string,
        settings: Settings
    ) {
        this._id = id
        this._name = name
        this._slug = slug
        this._applications = applications
        this._domain = domain
        this._settings = settings
    }

    public get id(): string {
        return this._id
    }

    public get name(): string {
        return this._name
    }

    public get slug(): string {
        return this._slug
    }

    public get applications(): Application[] {
        return this._applications
    }

    public get domain(): string {
        return this._domain
    }

    public get settings(): Settings {
        return this._settings
    }
}

export interface OrganizationUpdate {
    settings: Partial<Settings>
}
