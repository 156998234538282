import { RoleEnum } from './enums'
import { Organization } from './Organization'

export class Scope {
    private _organization: Organization
    private _role: RoleEnum

    constructor(organization: Organization, role: RoleEnum) {
        this._organization = organization
        this._role = role
    }

    public get organization(): Organization {
        return this._organization
    }

    public get role(): RoleEnum {
        return this._role
    }
}

export class ScopeUpdate {
    readonly organization_id: string
    readonly role: RoleEnum

    constructor(base: ScopeUpdate) {
        this.organization_id = base.organization_id
        this.role = base.role
    }
}
