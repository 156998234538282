import axios, { AxiosError } from 'axios'

import {
    ApplicationDTO,
    ApplicationDTOFactory,
    ApplicationRaw,
    ApplicationTypeDTO,
    IApplicationAPI,
    ProviderTypeDTO,
    ProviderTypeDTOFactory,
    ProviderTypeRaw
} from '@/api/application'
import { CancelSource, makeURL } from '@/api/config'
import { ServerError } from '@/api/errors/ServerError'

export class ApplicationAPI implements IApplicationAPI {
    private routes = {
        getApplications: () => makeURL('/account/applications/'),
        getProviderTypes: () => makeURL('/account/applications/provider-type/')
    }

    async retrieveApplications(cancelToken?: CancelSource): Promise<ApplicationDTO[] | null> {
        let rawApplications = null
        try {
            rawApplications = await axios.get<ApplicationRaw[]>(this.routes.getApplications(), {
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getApplications(), exception as AxiosError<ServerError>)
        }

        if (!rawApplications || !rawApplications.data) {
            return null
        }

        return rawApplications.data.map(ApplicationDTOFactory.fromRaw)
    }

    async retrieveProviderTypes(
        type: ApplicationTypeDTO,
        cancelToken?: CancelSource
    ): Promise<ProviderTypeDTO[] | null> {
        let rawProviderTypes = null
        try {
            rawProviderTypes = await axios.get<ProviderTypeRaw[]>(this.routes.getProviderTypes(), {
                params: {
                    application_type: type
                },
                cancelToken: cancelToken?.token
            })
        } catch (exception) {
            this.handleError(this.routes.getProviderTypes(), exception as AxiosError<ServerError>)
        }

        if (!rawProviderTypes || !rawProviderTypes.data) {
            return null
        }

        return rawProviderTypes.data.map(ProviderTypeDTOFactory.fromRaw)
    }

    private handleError(url: string, error: AxiosError<ServerError>): void {
        if (axios.isCancel(error)) {
            return
        }

        if (error.response && error.response.status === 500) {
            //Server sent error
            console.error(url)
            console.error(error)
        } else if (error.request) {
            // request sent but no answer received
        }
    }
}
