import { PartialUserDTO } from './PartialUserDTO'
import { PartialUserRaw } from './PartialUserRaw'
import { ScopeDTOFactory } from './ScopeDTOFactory'

export abstract class PartialUserDTOFactory {
    static fromRaw(userRaw: PartialUserRaw): PartialUserDTO {
        return new PartialUserDTO(
            userRaw.id,
            userRaw.first_name,
            userRaw.last_name,
            userRaw.email,
            userRaw.last_login,
            userRaw.role ? ScopeDTOFactory.fromRoleRaw(userRaw.role) : null,
            userRaw.login_type,
            userRaw.billing_type
        )
    }
}
