import { PreferencesDTO, PreferencesUpdateDTO } from './PreferencesDTO'
import { PreferencesRaw } from './PreferencesRaw'

export abstract class PreferencesDTOFactory {
    static fromRaw(raw: PreferencesRaw): PreferencesDTO {
        return new PreferencesDTO(raw.language)
    }

    static fromUpdateDTO(dto: Partial<PreferencesUpdateDTO>): Partial<PreferencesRaw> {
        return {
            language: dto.language || undefined
        }
    }
}
