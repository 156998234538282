import { Geometry } from 'geojson'

export class DmaDTO {
    constructor(
        readonly id: string,
        readonly zoneId: string,
        readonly externalId: string,
        readonly name: string,
        readonly description: string,
        readonly timezone: string,
        readonly active: boolean,
        readonly pipeLength: number,
        readonly timeStep: number,
        readonly averageWindow: number,
        readonly gracePeriod: number,
        readonly inhabitantsCount: number | null,
        readonly averagePressure: number | null,
        readonly nightflowStartHour: string,
        readonly nightflowEndHour: string,
        readonly minimumNightFlow: number | null,
        readonly invoicedWaterVolumePerYear: number,
        readonly geometry: Geometry | null
    ) {}
}
